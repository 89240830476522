import { EventOptions } from '@amplitude/analytics-types';

import { ampli } from '@/__ampli__';
import { CurrentUser } from '@/__codegen__/graphql';

import { pageViewTrackingEnrichment } from './EnrichmentPlugins';

export const setUpAmplitude = () => {
  const enableTracking = ['staging', 'production'].includes(process.env.REACT_APP_ENV as string);

  ampli.load({
    client: {
      apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY as string,
      configuration: {
        // defaultTracking: true,//toggle this to test amplitude locally
        defaultTracking: enableTracking,
      },
    },
    // disabled: false, //toggle this to test amplitude locally
    disabled: !enableTracking,
  });
  ampli.client.add(pageViewTrackingEnrichment());
};

export const setAmplitudeUser = (user :CurrentUser) => {
  const option = {
    userId: user.id,
    companyId: user.company.id,
    companyName: user.company.name,
  };

  ampli.identify(user.id, option as EventOptions);

  ampli.client.setGroup('company_id', user.company.id);
  ampli.client.setGroup('company_name', user.company.name);
  ampli.client.setGroup('selected_team_id', user.selectedCustomerTeamId);
};

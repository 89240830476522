import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { createRoot } from 'react-dom/client';
import '../shared/index.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Auth0ProviderWithHistory } from '@/shared/provider/Auth0ProviderWithHistory';
import { CurrentUserProvider } from '@/shared/provider/CurrentUserProvider';

import App from './App';
import { store } from './redux/store';
import { ApolloWrapper } from '../graphql/ApolloWrapper';
import './i18n';
import * as serviceWorker from '../serviceWorker';

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  const container = document.getElementById('root');
  const root = createRoot(container as HTMLElement);
  root.render(
    <React.StrictMode>
      <LDProvider>
        <Provider store={store}>
          <BrowserRouter>
            <Auth0ProviderWithHistory>
              <ApolloWrapper>
                <CurrentUserProvider>
                  <App />
                </CurrentUserProvider>
              </ApolloWrapper>
            </Auth0ProviderWithHistory>
          </BrowserRouter>
        </Provider>
      </LDProvider>
    </React.StrictMode>,
  );
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
